





































































































@import "../../styles/env";

.timeline {
  margin-top: 2rem;
  margin-left: 2rem;
  margin-bottom: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 4rem;

  position: relative;

  &::before {
    width: 0.25rem;

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;

    background-color: #D3D3D3;

    content: "";
  }

  .event {
    position: relative;

    h4 {
      padding-bottom: 1rem;
    }

    &::before {
      width: 1rem;
      height: 1rem;

      position: absolute;
      top: 50%;
      right: calc(100% + 2.875rem);

      background-color: #FFF;

      border: 0.5rem $primary-colour solid;

      transform: translateY(-50%) rotate(45deg);

      content: "";
    }

    + .event {
      margin-top: 5rem;
    }
  }
}
